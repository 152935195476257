export default class Client{

    //create static method for set or get a unique identifier for the user device
    //we will use sessionstorage to read the identifier or create a new one and save to storage
    //we will use the user ip, user agent and language to create the identifier
    static getDeviceId(): string {
        let deviceId = sessionStorage.getItem('did');
        if (!deviceId) {
            deviceId = btoa(encodeURIComponent(navigator.userAgent + navigator.language + Math.random() + new Date().getTime()));
            sessionStorage.setItem('did', deviceId);
            //set it also as a cookie
            document.cookie = `did=${deviceId};path=/;max-age=31536000`;
        }
        return deviceId;
    }
}
